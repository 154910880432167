var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    [
      !_vm.isSimpleTable
        ? _c(
            "v-row",
            [
              _c(
                "v-col",
                [
                  _c("domain-workspace-toolbar", {
                    attrs: {
                      backLink: _vm.backLink,
                      uuid: _vm.domainUuid,
                      subtitle: "Jobs"
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "subtitle",
                          fn: function() {
                            return undefined
                          },
                          proxy: true
                        },
                        {
                          key: "content",
                          fn: function() {
                            return [
                              _c("refresh-button", {
                                staticClass: "mr-2",
                                attrs: { refreshFunction: _vm.refreshItems }
                              })
                            ]
                          },
                          proxy: true
                        }
                      ],
                      null,
                      false,
                      1835314539
                    )
                  })
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _c(
        "v-card",
        {
          staticClass: "mb-4",
          attrs: { flat: _vm.isSimpleTable, loading: _vm.isLoading }
        },
        [
          _c(
            "v-card-text",
            [
              _c("v-data-table", {
                attrs: {
                  headers: _vm.loadJobsTable.headers,
                  items: _vm.loadJobsTable.data,
                  "sort-by": "date",
                  "sort-desc": "",
                  dense: _vm.isSimpleTable,
                  "hide-default-footer": ""
                },
                scopedSlots: _vm._u([
                  {
                    key: "item.label",
                    fn: function(ref) {
                      var item = ref.item
                      return [
                        _c(
                          "a",
                          {
                            on: {
                              click: function($event) {
                                $event.stopPropagation()
                                return _vm.goToLoadJob(item)
                              }
                            }
                          },
                          [_vm._v(_vm._s(item.label))]
                        )
                      ]
                    }
                  },
                  {
                    key: "item.type",
                    fn: function(ref) {
                      var item = ref.item
                      return [
                        _vm._v(
                          " " +
                            _vm._s(_vm.getFriendlyLoadJobType(item.type)) +
                            " "
                        )
                      ]
                    }
                  },
                  {
                    key: "item.summary",
                    fn: function(ref) {
                      var item = ref.item
                      return [
                        item.state === _vm.LoadJobStateName.DONE
                          ? _c(
                              "span",
                              {
                                attrs: {
                                  title:
                                    _vm.getTotalOperationsSucceeded(item) +
                                    " of " +
                                    _vm.getTotalOperations(item) +
                                    " (" +
                                    _vm.getTotalSuccessPercent(item) +
                                    "%)"
                                }
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.getTotalSuccessPercent(item)) +
                                    "% "
                                )
                              ]
                            )
                          : _c("span", [_vm._v("—")])
                      ]
                    }
                  },
                  {
                    key: "item.state",
                    fn: function(ref) {
                      var item = ref.item
                      return [_c("job-status-icon", { attrs: { job: item } })]
                    }
                  },
                  {
                    key: "item.updatedAt",
                    fn: function(ref) {
                      var item = ref.item
                      return [
                        _vm._v(
                          " " + _vm._s(_vm.formatDbDate(item.updatedAt)) + " "
                        )
                      ]
                    }
                  },
                  {
                    key: "item.actions",
                    fn: function(ref) {
                      var item = ref.item
                      return [
                        _vm.isModelExpired(item)
                          ? _c("div", [
                              _c(
                                "span",
                                {
                                  attrs: {
                                    title:
                                      "This job is older than " +
                                      _vm.DOMO_EXPIRATION_TTL_DAYS +
                                      " so you can only view it"
                                  }
                                },
                                [_c("b", [_vm._v("EXPIRED")])]
                              )
                            ])
                          : _c(
                              "div",
                              [
                                _c(
                                  "v-icon",
                                  {
                                    staticClass: "mr-2",
                                    attrs: {
                                      title: "Browse Operations",
                                      md: ""
                                    },
                                    on: {
                                      click: function($event) {
                                        return _vm.goToDomainOperationBrowserPage(
                                          item.uuid
                                        )
                                      }
                                    }
                                  },
                                  [_vm._v("mdi-magnify")]
                                ),
                                _c("load-job-reports-button", {
                                  attrs: { loadJob: item }
                                })
                              ],
                              1
                            )
                      ]
                    }
                  }
                ])
              }),
              _c(
                "div",
                { staticClass: "text-center pt-2" },
                [
                  !_vm.isSimpleTable
                    ? _c("v-pagination", {
                        attrs: { length: _vm.loadJobsTable.pageCount },
                        on: {
                          input: function($event) {
                            return _vm.getNextPage()
                          }
                        },
                        model: {
                          value: _vm.loadJobsTable.page,
                          callback: function($$v) {
                            _vm.$set(_vm.loadJobsTable, "page", $$v)
                          },
                          expression: "loadJobsTable.page"
                        }
                      })
                    : _vm._e()
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c("confirm")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }