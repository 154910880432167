














































































import Confirm from '@/components/confirmation/Confirmation.vue';
import JobStatusIcon from '@/components/job-status-icon/JobStatusIcon.vue';
import LoadJobReportsButton from '@/components/load-job-reports-button/LoadJobReportsButton.vue';
import DomainWorkspaceToolbar from '@/components/navigation/DomainWorkspaceToolbar.vue';
import RefreshButton from '@/components/navigation/RefreshButton.vue';
import { MAX_ITEMS_PER_PAGE, RunQueryOptions } from '@/module/api/common';
import { useDomainApi } from '@/module/api/domain';
import { DOMO_EXPIRATION_TTL_DAYS, isModelExpired } from '@/module/api/domo';
import {
  LoadJobModel,
  LoadJobStateName,
  getFriendlyLoadJobType,
  getJobProgressLabel,
  getJobProgressPercentCompletion,
  getLoadJobStateNameFriendly,
  getTotalOperations,
  getTotalOperationsSucceeded,
  getTotalSuccessPercent,
  isNewJobAndWizardIsNotComplete,
  useLoadJobApi,
} from '@/module/api/load-job';
import router from '@/router';
import { formatDbDate, usePagination } from '@/utils';
import { computed, defineComponent, reactive, toRefs } from '@vue/composition-api';
import Vue from 'vue';

const { selectedItem: selectedDomain, isLoading: isDomainLoading, refreshItem: refreshDomain } = useDomainApi();

const { getItems: getLoadJobs, items: loadJobs, isLoading: isLoadJobLoading } = useLoadJobApi();

const {
  areThereMorePages,
  getNextPageQueryParams,
  emptyPageableTable,
  resetPageableTable,
  cachePage,
  applyPageChange,
} = usePagination();

const state = reactive({
  domainUuid: '',
  isDownloadingReport: false,
  isSimpleTable: false,
});

const loadJobsTable = reactive({
  ...emptyPageableTable(),
  headers: computed(() => {
    return [
      { text: 'State', value: 'state', sortable: false, width: 50 },
      { text: 'Label', value: 'label', sortable: false },
      { text: 'Type', value: 'type', sortable: false },
      { text: 'Success', value: 'summary', sortable: false },
      { text: 'Date', value: 'updatedAt', sortable: false },
      { text: 'Actions', value: 'actions', align: 'left', sortable: false, width: 100 },
    ];
  }),
  itemsPerPage: 1,
});

const queryLoadJobs = async (runQueryOptions: RunQueryOptions) => {
  const query = runQueryOptions.nextPage
    ? getNextPageQueryParams({
        page: loadJobsTable.page,
      })
    : { page: loadJobsTable.page };
  query['filter[domainUuid]'] = selectedDomain.value?.uuid;
  const getLoadJobsParams = {
    query,
    raw: true,
  };
  const getLoadJobsRes = await getLoadJobs(getLoadJobsParams);
  loadJobsTable.data = getLoadJobsRes?._embedded;
  cachePage(loadJobsTable, loadJobsTable.data);
  const areMorePages = areThereMorePages(getLoadJobsRes);
  if (areMorePages) {
    loadJobsTable.pageCount = loadJobsTable.pageCount + 1;
  }
  return loadJobsTable.data;
};

const getNextPage = async () => {
  applyPageChange(loadJobsTable, async () => queryLoadJobs({ nextPage: true }));
};

const initializeTableData = async () => {
  if (!selectedDomain.value?.uuid) {
    Vue.$log.debug('ImportJobList: No domain selected');
    return;
  }
  const getLoadJobsRes = await getLoadJobs({
    query: {
      'filter[domainUuid]': selectedDomain.value?.uuid,
      limit: MAX_ITEMS_PER_PAGE,
    },
    raw: true,
  });
  resetPageableTable(loadJobsTable);
  const areMorePages = areThereMorePages(getLoadJobsRes);
  if (areMorePages) {
    loadJobsTable.pageCount = 2;
  }
  loadJobsTable.data = getLoadJobsRes._embedded;
  cachePage(loadJobsTable, loadJobsTable.data);
};

const initializeData = async () => {
  await refreshDomain(state.domainUuid);
  await initializeTableData();
};

const goToWorkspaceImportJobDetailPage = (jobUuid: string) => {
  router.push({
    name: 'WorkspaceImportJobDetail',
    params: { jobUuid, uuid: selectedDomain.value?.uuid },
  });
};

const goToDomainOperationBrowserPage = (jobUuid: string) => {
  router.push({
    name: 'LoadJobOperationBrowser',
    params: {
      uuid: selectedDomain.value?.uuid,
      jobUuid: jobUuid,
    },
  });
};

const goToFinishWizardPage = (jobUuid: string) => {
  router.push({
    name: 'ImportJobImportSetupWizard',
    params: {
      jobUuid: jobUuid,
    },
  });
};

const goToLoadJob = (loadJob: LoadJobModel): void => {
  if (loadJob === undefined || loadJob.uuid === undefined) {
    return;
  }
  if (isNewJobAndWizardIsNotComplete(loadJob)) {
    goToFinishWizardPage(loadJob.uuid);
  } else {
    goToWorkspaceImportJobDetailPage(loadJob.uuid);
  }
};

const refreshItems = () => {
  initializeData();
};

const reset = async () => {
  return;
};

// So that the parent component can refresh this component's data table
export function useImportJobList() {
  return {
    refreshItems,
  };
}

export default defineComponent({
  name: 'WorkspaceImportJobList',
  components: {
    RefreshButton,
    Confirm,
    DomainWorkspaceToolbar,
    JobStatusIcon,
    LoadJobReportsButton,
  },
  props: {
    uuid: {
      type: String,
    },
    simpleTable: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    state.domainUuid = props.uuid || '';
    state.isSimpleTable = props.simpleTable;
    initializeData();

    return {
      ...toRefs(state),
      reset,
      formatDbDate,
      refreshItems,
      selectedDomain,
      loadJobsTable,
      LoadJobStateName,
      isLoadJobLoading,
      applyPageChange,
      queryLoadJobs,
      getNextPage,
      goToLoadJob,
      goToWorkspaceImportJobDetailPage,
      goToDomainOperationBrowserPage,
      isNewJobAndWizardIsNotComplete,
      getFriendlyLoadJobType,
      getLoadJobStateNameFriendly,
      getJobProgressPercentCompletion,
      getJobProgressLabel,
      getTotalOperations,
      getTotalOperationsSucceeded,
      getTotalSuccessPercent,
      isModelExpired,
      DOMO_EXPIRATION_TTL_DAYS,
      isLoading: computed(() => {
        return isDomainLoading.value || isLoadJobLoading.value;
      }),
      backLink: `/domain/${props.uuid}/workspace`,
    };
  },
});
